<template>
  <div class="AirQualityEcharts_wrap">
    <div id="AirQualityEcharts" :style="{width: '100%', height: '185px'}">
    </div>
    <div class="grade">良</div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById('AirQualityEcharts'))
      myChart.setOption({
        tooltip: {
          show: false,
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            // splitLine: {
            //   show: false,
            // },
            // axisLabel: {
            //   show: false,
            // },
            // 指针
            pointer: {
              show: false
            },

            // 样式
            axisLine: {
              // 背景是否圆角
              roundCap: true,
              lineStyle: {
                width: 12,
                //背景色
                color: [[1, 'rgba(13,93,84,.8)']]
                // color: ['#0d5d54']
              }
            },

            // 内层细条
            axisTick: {
              show: false
            },

            // 内层粗条
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            name: '空气质量',
            type: 'gauge',
            // 进度条
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                // borderColor: '#51dbb2'
              }
            },
            color: ['#51dbb2'],
            center: ['55%', '43%'],
            radius: '80%',

            // 中间文字
            detail: {
              valueAnimation: true,
              formatter: '{value}',
              fontSize: 40,
              offsetCenter: [0, '-8%'],
              color: '#fff'
            },
            title: {
              show: false
            },
            data: [
              {
                value: 52,
                name: '数值'
              }
            ],
            
          }
        ]
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .AirQualityEcharts_wrap{
    display: flex;
    position: relative;
    .grade{
      position: absolute;
      bottom: 40px;
      left: 104px;
      color: #51dbb2;
      font-size: 20px;
    }
  }
</style>