<template>
    <div class="smart_right_content">
        <div class="smart_right_wrapBg"></div>
        <div class="smart_right_wrap">
            <Monitor />
        </div>
    </div>
</template>
<script>
    import '@/assets/css/smark/smark.less'
    import Monitor from '@/components/smartParkRight/Monitor.vue'
    export default {
      components: {
        Monitor,
      },
        data () {
            return {
                
            }
        }
    }
</script>
<style lang="less" scoped>
  .smart_right_wrap{
    position: relative;
    z-index: 10;
  }
</style>