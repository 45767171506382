<template>
  <div>
    <Title :txt="'告警信息'" />
    <div class="cont">
      <div class="cont_item">
        <HomeIcon
            :width="'35'"
            :height="'35'"
            :iconSize="'0.9'"
            :absoluteValue="37"
            :src="require('@/assets/images/smartParkLeftBottom5.png')" :color="'#5e8fed'" />
        <p>烟雾告警： 
          <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="400"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
          <span class="company">次</span></p>
      </div>
      <div class="cont_item">
        <HomeIcon
            :width="'35'"
            :height="'35'"
            :iconSize="'0.9'"
            :absoluteValue="37"
            :src="require('@/assets/images/smartParkLeftBottom6.png')" :color="'#4e59e3'" />
        <p>燃气告警：
          <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="400"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
          <span class="company">次</span>
        </p>
      </div>
      <div class="cont_item">
        <HomeIcon
            :width="'35'"
            :height="'35'"
            :iconSize="'0.9'"
            :absoluteValue="37"
            :src="require('@/assets/images/smartParkLeftBottom7.png')" :color="'#714ec9'" />
        <p>陌生人告警： 
          <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="400"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
          <span class="company">次</span>
        </p>
      </div>
      <div class="cont_item">
        <HomeIcon
            :width="'35'"
            :height="'35'"
            :iconSize="'0.9'"
            :absoluteValue="37"
            :src="require('@/assets/images/smartParkLeftBottom8.png')" :color="'#1eb3c6'" />
        <p>车辆告警： 
          <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="400"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
          <span class="company">次</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import HomeIcon from '@/components/HomeIcon.vue'
import ICountUp from 'vue-countup-v2';


export default {
  components: {
    Title,
    HomeIcon,
    ICountUp
  }
}
</script>
<style lang="less" scoped>
  .cont{
    .company{
      font-size: 12px !important;
    }
    padding: 27px 30px;
    .cont_item{
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      p{
        margin-left: 12px;
        font-size: 17px;
      }
      span{
        font-size: 26px;
      }
      sub{
        font-size: 16px;
      }
    }
  }
</style>