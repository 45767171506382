<template>
  <div class="ReportingInfo_wrap">
    <Title :txt="'人脸识别通行情况'" />
    <div class="cont">
      <div class="cont_box">
        <div class="cont_box_top">
          <img style="width:15px;" src="~@/assets/images/smartParkLeftMain3.png" alt="">
          <p>员工通行</p>
        </div>
        <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="48567"
              :options="{useGrouping: true,separator: ',', decimalPlaces: '0'}"/>
        <!-- <img class="cont_box_bg" src="~@/assets/images/smartParkLeftMain1.png" alt=""> -->
      </div>
      <div class="cont_box">
        <div class="cont_box_top">
          <img src="~@/assets/images/smartParkLeftMain4.png" alt="">
          <p>陌生人警告</p>
        </div>
        <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="48567"
              :options="{useGrouping: true,separator: ',', decimalPlaces: '0'}"/>
        <!-- <img class="cont_box_bg" src="~@/assets/images/smartParkLeftMain2.png" alt=""> -->
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import ICountUp from 'vue-countup-v2';


export default {
  components: {
    Title,
    ICountUp
  }
}
</script>
<style lang="less" scoped>
  .cont{
    .cont_box_top{
      display: flex;
      justify-content: center;
      padding-top: 20px;
      img{
        width: 20px;
        height: 19px;
      }
      p{
        margin: 0 5px;
        font-size: 18px;
      }
    }
    .cont_box{
      width: 68%;
      text-align: center;
      position: relative;
      // padding: 20px 0px;
      margin-left: 20px;
      margin-top: 25px;
      margin-bottom: 30px;
      padding-bottom: 10px;
      .cont_box_bg{
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    & .cont_box:nth-child(1) {
      background: url('./../../assets/images/smartParkLeftMain1.png') 100% 100% / cover no-repeat;
    }
    & .cont_box:nth-child(2) {
      background: url('./../../assets/images/smartParkLeftMain2.png') 100% 100% / cover no-repeat;
    }
    .num_txt{
      font-size: 32px;
      line-height: 34px;
    }
  }    
</style>