<template>
    <div class="smart_left_content">
        <div class="smart_left_wrapBg"></div>
        <div class="smart_left_wrap">
            <AirQuality />
            <ReportingInfo />
            <TrafficConditions />
        </div>
    </div>
</template>
<script>
    import '@/assets/css/smark/smark.less'
    import AirQuality from '@/components/smartParkLeft/AirQuality.vue'
    import ReportingInfo from '@/components/smartParkLeft/ReportingInfo.vue'
    import TrafficConditions from '@/components/smartParkLeft/TrafficConditions.vue'
    export default {
      components: {
        AirQuality,
        ReportingInfo,
        TrafficConditions
      },
        data () {
            return {
                
            }
        }
    }
</script>