<template>
  <div class="Monitor_wrap">
    <TitleRight :txt="'重点区域实时图像'" />
    <div class="cont">
      <div class="cont_item">
        <img src="~@/assets/images/smartParkRightTop1.png" alt="">
        <p class="cont_item_txt">东南门监控</p>
      </div>
      <div class="cont_item">
        <img src="~@/assets/images/smartParkRightTop1.png" alt="">
        <p class="cont_item_txt">东南门监控</p>
      </div>
      <div class="cont_item">
        <img src="~@/assets/images/smartParkRightTop1.png" alt="">
        <p class="cont_item_txt">东南门监控</p>
      </div>
    </div>
  </div>
</template>
<script>
import TitleRight from '@/components/TitleRight.vue'

export default {
  components: {
    TitleRight
  }
}
</script>
<style lang="less" scoped>
  .Monitor_wrap{
    position: relative;
    z-index: 10;
    img{
      display: block;
    }
    .cont{
      }
    .cont_item{
      margin-top: 16px;
    }
    .cont_item_txt{
      text-align: center;
      font-size: 18px;
      margin-top: 12px;
    }
  }
</style>