<template>
  <div class="AirQuality_wrap">
    <Title :txt="'空气质量'" />
    <div class="base_info">
      <div class="base_info_box">
        <img src="~@/assets/images/smartParkLeftTop1.png" alt="">
        <p>温度 <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="12"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/><span class="company">℃</span></p>
      </div>
      <div class="base_info_box">
        <img src="~@/assets/images/smartParkLeftTop2.png" alt="">
        <p>相对湿度 <ICountUp class="num_txt" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="12"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/><span class="company">℃</span></p>
      </div>
    </div>
    <div class="cont">
      <div class="cont_left">
        <AirQualityEcharts v-if="showCarAccess" />
      </div>
      <div class="cont_right">
        <div class="cont_right_box">
          <div class="cont_right_item">
            <p class="cont_right_itemLeft">PM2.5：</p>
            <div>
              <!-- <span class="num">6</span> -->
              <ICountUp class="num" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="6"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
              <span class="company">μg/m³</span>
            </div>
          </div>
          <div class="cont_right_item">
            <p class="cont_right_itemLeft">TVOC：</p>
            <div>
              <!-- <span class="num">0.16</span> -->
              <ICountUp class="num" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="0.16"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '2'}"/>
              <span class="company">mg/m³</span>
            </div>
          </div>
          <div class="cont_right_item">
            <p class="cont_right_itemLeft">CO：</p>
            <div>
              <!-- <span class="num">2</span> -->
              <ICountUp class="num" :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="2"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
              <span class="company">μg/m³</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import AirQualityEcharts from '@/components/smartParkLeft/AirQualityEcharts.vue'
import ICountUp from 'vue-countup-v2';


export default {
  components: {
    Title,
    AirQualityEcharts,
    ICountUp
  },
  data() {
    return {
      showCarAccess: false
    }
  },
  mounted() {
    setInterval(() => {
        this.showCarAccess = true
    }, 1000);
  }
}
</script>
<style lang="less" scoped>
  
  .base_info{
    display: flex;
    // justify-content: space-between;
    margin: 25px 20px;
    & div:nth-child(1) {
      margin-right: 30px;
    }
  }
  .base_info_box{
    display: flex;
    align-items: center;
    p{
      margin: 0 6px;
      font-size: 18px;
    }
    span{
      font-size: 25px;
    }
  }
  .cont{
    display: flex;
  }
  .cont>div{
    width: 50%;
    box-sizing: border-box;
  }
  .cont_right_box{
    margin-top: 28px;
    padding-bottom: 55px;
  }
  .cont_right_item{
    display: flex;
    margin-left: 16px;
    margin-top: 10px;
    .num{
      font-size: 20px;
      margin-right: 5px;
    }
    p, .company{
      color: #cdd0d9;
      font-size: 18px;
    }
  }
  .cont_right_itemLeft{
    width: 68px;
  }
  .base_info .company{
    font-size: 14px;
  }
</style>